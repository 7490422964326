import { useState, useCallback } from 'react'

const useFetchCompany = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)

  const companyURL = process.env.REACT_APP_COMPANIES_URL

  const fetchCompanies = useCallback(async () => {
    setIsLoading(true)
    setData(null)
    setError(null)

    try {
      const response = await fetch(companyURL)
      if (!response.ok) {
        setError('Error status ', response.status)
      } else {
        const responseJson = await response.json()
        setData(responseJson)
      }
    } catch (error) {
      setError('Network error')
    }

    setIsLoading(false)
  }, [companyURL])

  return { fetchCompanies, isLoading, error, data }
}

export default useFetchCompany
