import './App.scss'
import useFetchCompany from './hooks/useFetchCompany'
import CompanyList from './components/CompanyList'
import { useEffect, useState } from 'react'
import CompanyListOptionsForm from './components/CompanyListOptionsForm'
import processCompanyListData from './lib/processCompanyListData'

function App() {
  const { fetchCompanies, data, error, isLoading } = useFetchCompany()
  const [listOptions, setListOptions] = useState({
    filterCountry: '',
    filterIndustry: '',
    sortingOrder: 'asc',
    sortByField: 'name',
  })

  useEffect(() => {
    fetchCompanies()
  }, [fetchCompanies])

  const processedData = data ? processCompanyListData(data, listOptions) : []

  return (
    <div className="App">
      <div className="list-container">
        <CompanyListOptionsForm listOptionsHandler={setListOptions} />
        {data && <CompanyList data={processedData} />}
        {isLoading && (
          <p className="state-loading">Waiting for server response ...</p>
        )}
        {error && (
          <p className="state-error">There was error fetching data : {error}</p>
        )}
      </div>
    </div>
  )
}

export default App
