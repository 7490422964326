import './CompanyListOptionsForm.scss'

export default function CompanyListOptionsForm({ listOptionsHandler }) {
  const handleSubmit = (e) => {
    e.preventDefault()
    const data = {}
    const formData = new FormData(e.target)
    formData.forEach((value, key) => (data[key] = value))
    listOptionsHandler(data)
  }

  return (
    <form className="company-list-options-form" onSubmit={handleSubmit}>
      <div id="search-block" className="block">
        <div className="form-control">
          <label htmlFor="filterCountry">Search Country</label>
          <input name="filterCountry" id="filterCountry" autoComplete="off" />
        </div>

        <div className="form-control">
          <label htmlFor="filterIndustry">Search Industry</label>
          <input name="filterIndustry" id="filterIndustry" autoComplete="off" />
        </div>
      </div>
      <div id="sort-block" className="block">
        <div className="form-control">
          <label htmlFor="sortByField">Sort By </label>
          <select name="sortByField" id="sortByField">
            <option value="name">Name</option>
            <option value="numberOfEmployees">Employees</option>
          </select>
        </div>
        <div className="form-control">
          <label htmlFor="sortingOrder">Sort Order</label>
          <select name="sortingOrder" id="sortingOrder">
            <option value="asc">Ascending</option>
            <option value="desc">Descending</option>
          </select>
        </div>
      </div>

      <div className="block">
        <button type="submit">Apply Filters</button>
      </div>
    </form>
  )
}
