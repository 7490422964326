import './CompanyList.scss'

export default function CompanyList({ data }) {
  return (
    <div className="company-list">
      <table aria-label="Company List">
        <thead className="header">
          <tr className="row">
            <th>Name</th>
            <th>Country</th>
            <th>Industry</th>
            <th>Employees</th>
          </tr>
        </thead>
        <tbody className="records">
          {data.map((row) => (
            <tr key={row.id} className="row">
              <td>{row.name}</td>
              <td>{row.country}</td>
              <td>{row.industry}</td>
              <td>{row.numberOfEmployees}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
