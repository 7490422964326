const filterData = (data, field, value) => {
  return data.filter((element) => {
    const elementValue =
      typeof element[field] === 'string'
        ? element[field].toLowerCase()
        : element[field]
    const filterValue = typeof value === 'string' ? value.toLowerCase() : value
    return elementValue.indexOf(filterValue) !== -1
  })
}

const sortData = (data, field, order) => {
  if (!order) return data

  return data.sort((a, b) => {
    const aField = typeof a == 'string' ? a[field].toLowerCase() : a[field]
    const bField = typeof b == 'string' ? b[field].toLowerCase() : b[field]

    let output = 0

    if (order === 'asc') {
      output = aField > bField ? 1 : -1
    }
    if (order === 'desc') {
      output = aField > bField ? -1 : 1
    }

    return output
  })
}

const processCompanyListData = (data, options) => {
  let processedData = data

  processedData = filterData(processedData, 'country', options.filterCountry)
  processedData = filterData(processedData, 'industry', options.filterIndustry)
  processedData = sortData(
    processedData,
    options.sortByField,
    options.sortingOrder
  )

  return processedData
}

export default processCompanyListData
